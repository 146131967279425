<script lang="ts">
  import { isLanguage, languages, defaultLocale } from "@zenstudy/lib/models";
  import { getRelativeLocaleUrl } from "astro:i18n";
  import { twMerge } from "tailwind-merge";

  let className = "";
  export { className as class };
  export let pathname: string;
  export let current: string | undefined;

  $: currentLocale = current ?? defaultLocale;
  $: path = extractPath();

  function extractPath() {
    let components = pathname.split("/");
    if (isLanguage(components[1]))
      components = [components[0]!, ...components.slice(2)];
    return components.join("/");
  }
</script>

<!-- Dropdown -->
<!-- TMP workaround https://daisyui.com/components/dropdown/#method-2-using-css-focus -->
<div class={twMerge("dropdown", className)}>
  <div tabindex="0" role="button" class="btn btn-ghost btn-square gap-1">
    <span>{currentLocale.toUpperCase()}</span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="size-4"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="m19.5 8.25-7.5 7.5-7.5-7.5"
      />
    </svg>
  </div>

  <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
  <ul
    tabindex="0"
    class={twMerge(
      "menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] min-w-fit gap-1 p-2 shadow",
    )}
  >
    {#each Object.entries(languages) as [lang]}
      <li class={currentLocale === lang ? "active" : ""}>
        <a href={getRelativeLocaleUrl(lang, path)}>{lang.toUpperCase()}</a>
      </li>
    {/each}
  </ul>
</div>
